/*
 * @Descripttion: *
 * @version: *
 * @Author: Fengshi
 * @Date: 2020-11-20 16:11:43
 * @LastEditors: Fengshi
 * @LastEditTime: 2020-11-20 16:16:04
 */
import DetailTable from './DetailTable.vue'
export default DetailTable
