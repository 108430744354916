/*
 * @Descripttion: *
 * @version: *
 * @Author: Fengshi
 * @Date: 2020-11-20 16:11:53
 * @LastEditors: Mingkit
 * @LastEditTime: 2020-11-22 18:21:47
 */
export default {
  name: 'DetailTable',
  data () {
    return {
      queryForm: {
        id: '',
        keyword: ''
      }
    }
  },
  methods: {
    tableSearch (reset) {
      if (reset) {
        this.$refs['w-table'].resetList()
      } else {
        this.$refs['w-table'].getList()
      }
    }
  },
  props: {
    id: {
      type: Number
    }
  },
  beforeMount () {
    this.$set(this.queryForm, 'id', this.id)
  }
}
